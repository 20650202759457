.swagger-ui
{
    @import '~tachyons-sass/tachyons.scss';
    @import 'mixins';
    @import 'variables';
    @import 'type';
    @import 'layout';
    @import 'buttons';
    @import 'form';
    @import 'modal';
    @import 'models';
    @import 'servers';
    @import 'table';
    @import 'topbar';
    @import 'information';
    @import 'authorize';
    @import 'errors';
    @include text_body();
    @import 'split-pane-mode';
    @import 'markdown';
}

li {
  line-height: 1.5em;
}
p {
  line-height: 1.4em;
}
pre code {
  padding: 7px 9px !important;
}
code {
  padding: 2px 3px !important;
  color: #0f9bd6 !important;
}
.swagger-ui .opblock.opblock-head {
  background: #fffbf6 !important;
  border-color: #ffe7a0 !important;
}
.swagger-ui .opblock.opblock-head .opblock-summary-method {
  background:#ffc107 !important;
}
.swagger-ui .opblock.opblock-head .opblock-summary {
  border-color:#ffc107 !important;
}
.swagger-ui .opblock.opblock-head .tab-header .tab-item.active h4 span:after {
  background:#ffc107 !important;
}

